<template>
  <Modal v-model="show" @on-cancel="dismissModal" width="80%" title="阅卷分配">
    <EmptyStatus v-if="isLoadingQuestion" icon="#icon-shuaxin1" hint="加载数据中" spin />
    <EmptyStatus v-else-if="allQuestions.length === 0" hint="没有需要批阅的主观题" />
    <div v-else class="exam-remark-allocate">
      <div class="wrapper">
        <div class="transfer-wrapper">
          <Transfer
            filterable
            v-if="teachers.length > 0"
            :titles="['教师列表', '已选中教师']"
            :data="teachers"
            :list-style="{ width: '300px', height: '500px' }"
            :target-keys="selectedTeacherIds"
            :render-format="transferRender"
            :filter-method="handleFilter"
            @on-change="handleTransferChange"
          />
        </div>
        <Divider v-if="selectedTeachers.length > 0">已选阅卷人 [{{ selectedTeachers.length }}]</Divider>
        <div v-for="(teacher, index) in selectedTeachers" :key="index">
          <div class="row-wrapper" style="display: flex;align-items: center; margin-top: 10px">
            <span class="form-item-label" style="margin-right: 10px">{{ teacher.realName }}</span>
            <Select v-model="teacherQuestionMap[teacher.userId]" multiple>
              <Option
                :disabled="allSelectedQuestions.indexOf(option.questionId) !== -1"
                v-for="option in allQuestions"
                :key="option.questionId"
                :value="option.questionId"
                >{{ `${option.sectionName} - ${option.questionOrder}${option.select ? ' [已选择]' : ''}` }}</Option
              >
            </Select>
          </div>
        </div>
      </div>
      <!--      <div>[TEACHER]: {{ selectedTeachers }}</div>-->
      <!--      <div>[____MAP]: {{ teacherQuestionMap }}</div>-->
      <!--      <div>[SL_QUES]: {{ allSelectedQuestions }}</div>-->
    </div>
    <div slot="footer">
      <Button v-if="allQuestions.length > 0" type="primary" size="large" @click="handleSubmit" :loading="isAllocating">保存分配</Button>
    </div>
  </Modal>
</template>

<script>
import DepartmentAPI from '@/api/department'
import ExamAPI from '@/api/exam'
import InputWrapper from '@/components/common/InputWrapper'
import EmptyStatus from '@/components/common/EmptyStatus'
export default {
  name: 'ExamRemarkAllocate',
  components: {
    InputWrapper,
    EmptyStatus
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    this.requestDepartments()
  },
  data() {
    return {
      show: this.value,
      showAllocateModal: false,
      examId: null,
      departments: [],
      allQuestions: [],
      teachers: [],
      selectedTeacherIds: [],
      selectedTeachers: [],
      targetKeys: [],
      teacherQuestionMap: {},
      isAllocating: false,
      isLoadingQuestion: false
    }
  },
  computed: {
    allSelectedQuestions() {
      return Object.values(this.teacherQuestionMap).reduce((pre, cur) => [...pre, ...cur], [])
    }
  },
  watch: {
    selectedTeacherIds(ids) {
      this.selectedTeachers = ids.map(id => this.teachers.find(teacher => teacher.userId + '' === id))
    },
    value(newVal) {
      this.show = newVal
      if (newVal === false) {
        this.reset()
      }
    },
    examId(newVal) {
      if (newVal) {
        this.requestRemarkAssignmentList()
      }
    }
  },
  methods: {
    async requestRemarkAssignmentList() {
      this.isLoadingQuestion = true
      try {
        const { res } = await ExamAPI.getRemarkAssignmentList(this.examId)
        this.allQuestions = res.allQuestions
        this.selectedTeacherIds = res.teacherAndAllocatedQuestions.map(teacher => teacher.teacherId + '')
        const map = {}
        res.teacherAndAllocatedQuestions.forEach(teacher => {
          map[teacher.teacherId] = teacher.markQuestionIds
        })
        this.teacherQuestionMap = map
      } finally {
        this.isLoadingQuestion = false
      }
    },
    async requestDepartments() {
      // 重置选中的人
      this.targetKeys = []
      const { res } = await DepartmentAPI.getDepartmentsTree()
      const flatDepartmentList = []
      this._flattenDepartment(res, flatDepartmentList)
      this.departments = flatDepartmentList
      const departmentIds = flatDepartmentList.map(dept => dept.id)
      this.requestUserOfDept(departmentIds)
    },
    async requestUserOfDept(deptIds) {
      if (deptIds.length === 0) {
        this.teachers = []
        return
      }
      const { res } = await DepartmentAPI.getStaffsByDepartments(deptIds)
      let staffs = []
      res.forEach(dept => (staffs = [...staffs, ...dept.staffs]))
      const ret = staffs.map(teacher => ({
        ...teacher,
        key: teacher.userId + ''
      }))
      this.teachers = ret
    },
    _flattenDepartment(tree, ret = []) {
      ret.push({ id: tree.departmentId, name: tree.name })
      if (Array.isArray(tree.children)) {
        tree.children.forEach(item => {
          this._flattenDepartment(item, ret)
        })
      }
    },
    setCurrentExamId(examId) {
      this.examId = examId
    },
    reset() {
      this.examId = null
    },
    async handleSubmit() {
      this.isAllocating = true
      const allocation = {}
      Object.keys(this.teacherQuestionMap).forEach(teacherId => {
        this.teacherQuestionMap[teacherId].forEach(questionId => {
          allocation[questionId] = +teacherId
        })
      })
      try {
        await ExamAPI.assignRemarkTeachers(this.examId, allocation)
        this.$message.success('阅卷分配情况保存成功')
      } finally {
        this.isAllocating = false
      }
    },
    dismissModal() {
      this.show = false
      this.$emit('input', false)
    },
    transferRender(item) {
      return item.realName
    },
    handleTransferChange(newSelectedTeacherIds) {
      this.selectedTeacherIds = newSelectedTeacherIds
    },
    handleFilter(data, query) {
      if (query === '') return true
      return data.realName.indexOf(query) !== -1
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/mixin';
.exam-remark-allocate {
  text-align: left;
}
.wrapper {
  margin-top: 10px;
  padding: 20px;
  background: white;
  .footer {
    margin-top: 10px;
    text-align: center;
  }
}
.transfer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-item-label {
  .--mixin-word-ellipsis;
  width: 80px;
  text-align: right;
}
</style>
